import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import PricingData from "../../content/pricing/index.json";
import FeaturesData from "../../content/pricing/features.json";
import CTAData from "../../content/pricing/cta.json";
import ResellersData from "../../content/pricing/resellers.json";
import TickerData from "../../content/home/ticker.json";
import HeroPagesCenter from "../../components/heroes/hero-pages-center";
import PricingPlans from "../../components/pricing/plans";
import SimpleCTA from "../../components/ctas/simple-cta";
import FeatureComparison from "../../components/pricing/features";
import SMSCalculator from "../../components/pricing/sms-calculator";
import ClientsTicker from "../../components/misc/clients-ticker";
import {Row, Col, Container} from "react-bootstrap";
import EmailPricing from "../../components/pricing/email-pricing";
import Resellers from "../../components/pricing/resellers";
import AddonsPricing from "../../components/pricing/addons-pricing"

const PricingIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo lang={intl.locale}
         title={PricingData[intl.locale].title}
         description={PricingData[intl.locale].text}
         pathname={location.pathname} />
    <HeroPagesCenter
      theme={`primary`}
      content={PricingData[intl.locale]}
    />
    <div className={`top-overflow-wrapper`}>
        <PricingPlans
            currencies={PricingData[intl.locale].currencies}
            plans={PricingData[intl.locale].plans}
        />
        <AddonsPricing />
         <Container>
            <Row>
                <Col lg={8}>
                    <EmailPricing />
                </Col>
                <Col lg={4}>
                    <Resellers content={ResellersData[intl.locale]} />
                </Col>
            </Row>
        </Container>
        <SMSCalculator />
        <Container>
            <FeatureComparison content={FeaturesData[intl.locale]} />
            <div className={`mb-5`}>
                <SimpleCTA theme={`primary`} content={CTAData[intl.locale]} />
            </div>
        </Container>
        <ClientsTicker content={TickerData[intl.locale]} />
    </div>
  </Layout>
)

export default injectIntl(PricingIndexPage)
