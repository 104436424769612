import React from "react";
import {injectIntl} from "gatsby-plugin-react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container} from "react-bootstrap";

class FeatureComparison extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleScroll = this.handleScroll.bind(this);

        this.state = {
            onScrollClass: "",
            elementPosition: 0
        };

    }
    componentDidMount() {
        let staticHeader = document.getElementById("StaticTableHeader"),
            boundingRectangle = staticHeader.getBoundingClientRect();

        this.setState({
            elementPosition: boundingRectangle.top + window.scrollY
        });

        window.addEventListener('scroll', this.handleScroll, false);
    }

    handleScroll(){
        if (this.state.elementPosition < window.scrollY) {
            this.setState({
                onScrollClass: "on-scroll"
            });
        } else{
            this.setState({
                onScrollClass: ""
            });
        }
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    render() {
        return (
            <div className={`feature-comparision`}>
                <div className={`title`}>{this.props.content.title}</div>
                <div className={`features-table`}>
                    <div className={`floating-table-header ` + this.state.onScrollClass}>
                        <Container>
                            <table>
                                <thead>
                                    <tr>
                                        <th aria-label="Plan" className={`col-value`}></th>
                                        {this.props.content.headers.map((header, i) => {
                                            return (
                                                <th key={i}>{header}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                            </table>
                        </Container>
                    </div>
                    <table>
                        <thead id={`StaticTableHeader`} className={this.state.onScrollClass}>
                            <tr>
                                <th aria-label="Plan" className={`col-value`}></th>
                                {this.props.content.headers.map((header, i) => {
                                    return (
                                        <th key={i}>{header}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                        {this.props.content.features.map((feature, i) => {

                            if (feature.section) {
                                return (
                                    <tr className={`section`} key={i}>
                                        <td className={`col-value`}>{feature.title}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )
                            } else {

                                let startup = feature.startup;
                                if (feature.icon) {
                                    startup = <div className={`feature-disabled`}><FontAwesomeIcon icon="times" /></div>;
                                    if (feature.startup) {
                                        startup = <div className={`feature-enabled`}><FontAwesomeIcon icon="check" /></div>;
                                    }
                                }

                                let online = feature.online;
                                if (feature.icon) {
                                    online = <div className={`feature-disabled`}><FontAwesomeIcon icon="times" /></div>;
                                    if (feature.online) {
                                        online = <div className={`feature-enabled`}><FontAwesomeIcon icon="check" /></div>;
                                    }
                                }

                                let enterprise = feature.enterprise;
                                if (feature.icon) {
                                    enterprise = <div className={`feature-disabled`}><FontAwesomeIcon icon="times" /></div>;
                                    if (feature.enterprise) {
                                        enterprise = <div className={`feature-enabled`}><FontAwesomeIcon icon="check" /></div>;
                                    }
                                }

                                return (
                                    <tr key={i}>
                                        <td className={`col-value`}>{feature.feature}</td>
                                        <td>{startup}</td>
                                        <td>{online}</td>
                                        <td>{enterprise}</td>
                                    </tr>
                                )
                            }
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default injectIntl(FeatureComparison);
