import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl";

const Ribbon = ({ intl, theme, orientation, text }) => (
    <div className={orientation === "right" ? `ribbon right ` + theme : `ribbon ` + theme}>
        <span>{text}</span>
    </div>
)

export default injectIntl(Ribbon)
