import React from 'react';
import {injectIntl, Link} from "gatsby-plugin-react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



function Resellers({ intl, content }) {

    return(
        <div className={`resellers`}>
            <Link className={`contact-link`} to={`/contact`}>
                <div className={`title`}>{content.title}</div>
                <div className={`text`} dangerouslySetInnerHTML={{__html: content.text}}></div>
                <div className={`icon`}><FontAwesomeIcon icon="arrow-right" /></div>
            </Link>
        </div>

    )

}

export default injectIntl(Resellers)

