import React from "react";
import {FormattedMessage, injectIntl} from "gatsby-plugin-react-intl";
import { Col, Form, FormControl, Modal, Row } from "react-bootstrap"

class EmailPricing extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showModal: false,
            selectedCurrency: "eur",
            price: {
                usd: "0.0003",
                eur: "0.00027"
            },
            plans: [
                // {
                //     key: "25,000",
                //     value: 25000,
                //     price: {
                //         usd: "29",
                //         eur: "19"
                //     },
                //     min: 50000,
                //     max: 250000
                // },
                {
                    key: "50,000",
                    value: 50000,
                    price: {
                        usd: "199",
                        eur: "149"
                    },
                    min: 100000,
                    max: 500000
                },
                {
                    key: "100,000",
                    value: 100000,
                    price: {
                        usd: "399",
                        eur: "299"
                    },
                    min: 200000,
                    max: 1000000
                },
                {
                    key: "250,000",
                    value: 250000,
                    price: {
                        usd: "599",
                        eur: "449"
                    },
                    min: 500000,
                    max: 2500000
                }
            ],
            selectedPlan: {
                key: "50,000",
                value: 50000,
                price: {
                    usd: "199",
                    eur: "149"
                },
                min: 100000,
                max: 500000
            },
            selectedNrMessages: 100000,
            totalCost: 149 + (100000 * 0.00027),
        };
    }

    componentDidMount(){

        if (!localStorage.getItem("notificareSelectedCurrency")) {
            localStorage.setItem("notificareSelectedCurrency", "eur");
        }

        this.setState({
            selectedCurrency: localStorage.getItem("notificareSelectedCurrency")
        });

        //Because storage event is never triggered in the same page
        //We need to keep an interval to check for changes in currency
        this.intervalId = setInterval(() => {
            this.setState({
                selectedCurrency: localStorage.getItem("notificareSelectedCurrency")
            });
            this.calculateCost();
        }, 1000);

        this.calculateCost();
    }

    componentWillUnmount(){
        clearInterval(this.intervalId);
    }

    handleOpen = (e) => {
        e.preventDefault();
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handlePlanChange = (e) => {
        let plan = this.state.plans.find((p) => {
            return p.value === parseInt(e.target.value);
        });

        this.setState({
            selectedPlan: plan,
            selectedNrMessages: plan.min
        });
        this.calculateCost();
    }

    handleMessagesChange = (e) => {
        this.setState({
            selectedNrMessages: e.target.value
        });
        this.calculateCost();
    }

    formatNumber = (value) => {
        return Intl.NumberFormat('en-US').format(value);
    }

    formatMoney = (value) => {
        return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(value);
    }

    calculateCost = () => {
        this.setState({
            totalCost: parseFloat(this.state.selectedPlan.price[this.state.selectedCurrency]) + (parseFloat(this.state.selectedNrMessages) * parseFloat(this.state.price[this.state.selectedCurrency]))
        });
    }

    render() {
        return (
            <div className={`email-pricing`}>
                <Row noGutters={true}>
                    <Col md={6}>
                        <div className={`side-bar`}>
                            <div className={`title`}>
                                <FormattedMessage id="components.emailPricing.title" />
                            </div>
                            <div className={`text`}>
                                <FormattedMessage id="components.emailPricing.text" />
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`email-price`}>
                            <div className={`email-price-wrapper`}>
                                <div className={`title`}><FormattedMessage id="components.emailPricing.price.messages" /></div>
                                <div className={`price`}>
                                    <span className={`currency-symbol`}>{this.state.selectedCurrency === "eur" ? "€" : "$"}</span>{this.state.price[this.state.selectedCurrency]}<span className={`periodicity`}><FormattedMessage id="components.emailPricing.price.perMessage" /></span>
                                </div>
                            </div>
                            <a href={`/`} onClick={this.handleOpen} aria-label="Price calculator" className={`price-calculator-link`}><FormattedMessage id="components.emailPricing.priceCalculator.link" /></a>
                        </div>
                    </Col>
                </Row>
                <Modal size={`lg`} centered show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="components.emailPricing.priceCalculator.modal.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={`form-area`}>
                            <div className="form-field-group">
                                <Form.Label><FormattedMessage id="components.emailPricing.priceCalculator.modal.form.plans.label" /></Form.Label>
                                <div className={`select-container`}>
                                    <FormControl as={`select`} value={this.state.selectedPlan.value} name={`selectedPlan`} className="form-field" onChange={this.handlePlanChange}>
                                        {this.state.plans.map((option, i) => {
                                            return (
                                              <option key={i} value={option.value}>{option.key}</option>
                                            )
                                        })}
                                    </FormControl>
                                </div>
                            </div>
                            <div className="form-field-group">
                                <Form.Label><FormattedMessage id="components.emailPricing.priceCalculator.modal.form.messages.label" /></Form.Label>
                                <div className={`range-container`}>
                                    <Form.Control className="input-range" value={this.state.selectedNrMessages} min={this.state.selectedPlan.min} max={this.state.selectedPlan.max} step={this.state.selectedPlan.value} type="range" onChange={this.handleMessagesChange} />
                                    <div className={`input-range-text`}>{this.formatNumber(this.state.selectedNrMessages)} <FormattedMessage id="components.emailPricing.priceCalculator.modal.form.messages.unit" /></div>
                                </div>
                            </div>
                            <div className="price-calculator-result">
                                <div className="total-cost">
                                    <div className={`title`}>
                                        <FormattedMessage id="components.emailPricing.priceCalculator.modal.result.label" />
                                    </div>
                                    <div className={`cost`}>
                                        <span className={`currency-symbol`}>{this.state.selectedCurrency === "eur" ? "€" : "$"}</span>{this.formatMoney(this.state.totalCost)}<span className={`periodicity`}><FormattedMessage id="components.emailPricing.priceCalculator.modal.result.unit" /></span>
                                    </div>
                                    <div className={`disclaimer`}>
                                        <FormattedMessage id="components.emailPricing.priceCalculator.modal.result.disclaimer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default injectIntl(EmailPricing);
