import React from "react";
import {FormattedMessage, injectIntl} from "gatsby-plugin-react-intl";
import { Col, Container, Form, FormControl, Row } from "react-bootstrap"

class AddonsPricing extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedCurrency: "eur",
            cost: 9,
            addOns: [
                {
                    value: "automation",
                    key: 'Automation Add-on',
                },
                {
                    value: "live_api",
                    key: 'Live API Add-on',
                },
                {
                    value: "passbook",
                    key: 'Loyalty Add-on',
                },
                {
                    value: "reports",
                    key: 'Reports Add-on',
                },
                {
                    value: "storage",
                    key: 'Storage Add-on',
                }
            ],
            plans: [
                {
                    key: "Online 50k",
                    value: 'Online 50k',
                    passbook: {
                        usd: "99",
                        eur: "75"
                    },
                    in_app_purchase: {
                        usd: "39",
                        eur: "29"
                    },
                    reports: {
                        usd: "39",
                        eur: "29"
                    },
                    live_api: {
                        usd: "99",
                        eur: "75"
                    },
                    automation: {
                        usd: "99",
                        eur: "75"
                    },
                    storage: {
                        usd: "99",
                        eur: "75"
                    },
                },
                {
                    key: "Online 100k",
                    value: 'Online 100k',
                    passbook: {
                        usd: "199",
                        eur: "149"
                    },
                    in_app_purchase: {
                        usd: "79",
                        eur: "59"
                    },
                    reports: {
                        usd: "79",
                        eur: "59"
                    },
                    live_api: {
                        usd: "199",
                        eur: "149"
                    },
                    automation: {
                        usd: "199",
                        eur: "149"
                    },
                    storage: {
                        usd: "199",
                        eur: "149"
                    },
                },
                {
                    key: "Online 250k",
                    value: 'Online 250k',
                    passbook: {
                        usd: "299",
                        eur: "225"
                    },
                    in_app_purchase: {
                        usd: "119",
                        eur: "89"
                    },
                    reports: {
                        usd: "119",
                        eur: "89"
                    },
                    live_api: {
                        usd: "299",
                        eur: "225"
                    },
                    automation: {
                        usd: "299",
                        eur: "225"
                    },
                    storage: {
                        usd: "299",
                        eur: "225"
                    },
                }
            ],
            selectedPlan: {
                key: "Online 50k",
                value: 'Online 50k',
                passbook: {
                    usd: "99",
                    eur: "75"
                },
                in_app_purchase: {
                    usd: "39",
                    eur: "29"
                },
                reports: {
                    usd: "39",
                    eur: "29"
                },
                live_api: {
                    usd: "99",
                    eur: "75"
                },
                automation: {
                    usd: "99",
                    eur: "75"
                },
                storage: {
                    usd: "99",
                    eur: "75"
                },
            },
            selectedAddOn: {
                value: "automation",
                key: 'Automation Add-on',
            },
        };
    }

    componentDidMount(){

        if (!localStorage.getItem("notificareSelectedCurrency")) {
            localStorage.setItem("notificareSelectedCurrency", "eur");
        }

        this.setState({
            selectedCurrency: localStorage.getItem("notificareSelectedCurrency")
        });

        //Because storage event is never triggered in the same page
        //We need to keep an interval to check for changes in currency
        this.intervalId = setInterval(() => {
            this.setState({
                selectedCurrency: localStorage.getItem("notificareSelectedCurrency")
            });
            this.calculateCost();
        }, 500);

        this.calculateCost();
    }

    componentWillUnmount(){
        clearInterval(this.intervalId);
    }

    handleOpen = (e) => {
        e.preventDefault();
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handlePlanChange = (e) => {
        let plan = this.state.plans.find((p) => {
            return p.value === e.target.value;
        });

        this.setState({
            selectedPlan: plan
        });
        this.calculateCost();
    }

    handleAddOnChange = (e) => {
        let addon = this.state.addOns.find((p) => {
            return p.value === e.target.value;
        });
        this.setState({
            selectedAddOn: addon
        });
        this.calculateCost();
    }

    formatNumber = (value) => {
        return Intl.NumberFormat('en-US').format(value);
    }

    formatMoney = (value) => {
        return Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(value);
    }

    calculateCost = () => {
        let cost = this.state.selectedPlan[this.state.selectedAddOn.value][this.state.selectedCurrency];
        this.setState({
            cost: parseFloat(cost)
        });
    }

    render() {
        return (
          <Container>
              <div className={`addons-pricing`}>
                  <Row noGutters={true}>
                      <Col md={4}>
                          <div className={`side-bar`}>
                              <div className={`title`}>
                                  <FormattedMessage id="components.addonsPricing.title" />
                              </div>
                              <div className={`text`}>
                                  <FormattedMessage id="components.addonsPricing.text" />
                              </div>
                          </div>
                      </Col>
                      <Col md={8}>
                          <Row noGutters={true}>
                              <Col md={6}>
                                  <div className={`form-area p-4`}>
                                      <div className="form-field-group">
                                          <Form.Label><FormattedMessage id="components.addonsPricing.form.plans.label" /></Form.Label>
                                          <div className={`select-container`}>
                                              <FormControl as={`select`} value={this.state.selectedPlan.value} name={`selectedPlan`} className="form-field" onChange={this.handlePlanChange}>
                                                  {this.state.plans.map((option, i) => {
                                                      return (
                                                        <option key={i} value={option.value}>{option.key}</option>
                                                      )
                                                  })}
                                              </FormControl>
                                          </div>
                                      </div>
                                  </div>
                              </Col>
                              <Col md={6}>
                                  <div className="price-calculator-result">
                                      <div className="total-cost">
                                          <div className={`form-area`}>
                                              <div className="form-field-group">
                                                  <div className={`select-container`}>
                                                      <FormControl as={`select`} value={this.state.selectedAddOn.value} name={`selectedAddOn`} className="form-field" onChange={this.handleAddOnChange}>
                                                          {this.state.addOns.map((option, i) => {
                                                              return (
                                                                <option key={i} value={option.value}>{option.key}</option>
                                                              )
                                                          })}
                                                      </FormControl>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className={`description`}>
                                              <FormattedMessage id={`components.addonsPricing.result.addons.${this.state.selectedAddOn.value}`} />
                                          </div>
                                          <div className={`cost`}>
                                              <span className={`currency-symbol`}>{this.state.selectedCurrency === "eur" ? "€" : "$"}</span>{this.formatMoney(this.state.cost)}<span className={`periodicity`}><FormattedMessage id="components.addonsPricing.result.unit" /></span>
                                          </div>
                                          <div className={`disclaimer`}>
                                              <FormattedMessage id="components.addonsPricing.result.disclaimer" />
                                          </div>
                                      </div>
                                  </div>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
              </div>
          </Container>
        )
    }
}

export default injectIntl(AddonsPricing);
